import axios, { AxiosInstance } from 'axios';

export type VimeoOutputType = 'xml' | 'json';

export class VimeoServiceClass {
  axios: AxiosInstance;

  constructor() {
    this.axios = axios.create({
      baseURL: 'https://vimeo.com/api/v2/video/',
    });
  }

  async getVideoData(videoId: string) {
    return await this._retrieve(videoId, 'json');
  }

  /**
   * Get thumbnail
   * @param videoId
   */
  async getThumbnail(videoId: string) {
    try {
      const data = await this._retrieve(videoId, 'json');

      const { thumbnail_large = '' } = data;

      return thumbnail_large;
    } catch (error) {
      // return '';
    }
  }

  /**
   * Retrieve data from vimeo and parse response
   * @param videoId
   * @param type
   */
  private async _retrieve(videoId: string, type: VimeoOutputType) {
    const data = await this.axios.get(`${videoId}.${type}`);
    return data.data[0];
  }
}

export const VimeoService = new VimeoServiceClass();
