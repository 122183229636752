















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class PackTile extends Vue {
  @Prop() pack!: any;

  getPackYear(date: string) {
    return new Date(date).getFullYear();
  }
}
